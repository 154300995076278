/* VERSION DESIGN - Non Material UI Styles for VALIDO Console build */

/* General styles
---------------------------------------------------------------------------------------------- */

/* Colour Pallet
----------------------------------------------------------------------------------------------
#EC6564 - Pink
#1c92ad - Green/Blue
#2A558C - Dark Blue
#76CEF8 - Light Blue
#94AECB - Flat Blue
#4B4B4B - Grey BG
#697071 - Grey Text

/* Font Sizes - converted from px to rem
----------------------------------------------------------------------------------------------
8px		- 	0.5rem
9px		- 	0.563rem
10px		- 	0.625rem
11px		- 	0.688rem
12px		- 	0.75rem
13px		-	0.813rem
14px		-	0.875rem
15px		-	0.938rem
16px		-	1rem
17px		-	1.063rem
18px		-	1.125rem
19px		-	1.188rem
20px		-	1.25rem
---------------------------------------------------------------------------------------------- */

/* Font weights
----------------------------------------------------------------------------------------------
200 - Extra-light
300 - Light
400 - Regular
600 - Semi-Bold
700 - Bold
800 - Extra-bold
900 - Black
---------------------------------------------------------------------------------------------- */


/* Base Styles */

/*

* {
	margin: 0;
	padding: 0;
	outline: 0;
}

*/

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 300;
  src: url(./assets/fonts/NunitoSans/NunitoSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  src: url(./assets/fonts/NunitoSans/NunitoSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 500;
  src: url(./assets/fonts/NunitoSans/NunitoSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  src: url(./assets/fonts/NunitoSans/NunitoSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 800;
  src: url(./assets/fonts/NunitoSans/NunitoSans-ExtraBold.ttf) format('truetype');
}


body {
  background: #f4f6f8;
  font-family: "Nunito Sans", sans-serif;
}

input, select, button {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

input, select, button {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

a:link, a:visited {
  outline: none;
  text-decoration: none;
}

a:hover, a:active {
  text-decoration: none;
  outline: none;
}

img {
  border: none;
}

img {
  width: 100%;
  height: auto;
}

/* Colours */

.teal {
  background: #34aebe;
  border: 3px solid #34aebe;
}

.yellow {
  background: #e3c02e;
  border: 3px solid #e3C02e;
}

.red {
  background: #e7352d;
  border: 3px solid #e7352d;
}

.green {
  background: #60cc46;
  border: 3px solid #60cc46;
}

.purple {
  background: #7a6e91;
  border: 3px solid #7a6e91;
}



/* Material UI Component overrides
---------------------------------------------------------------------------------------------- */

.button-subtext {
  font-size: 1.25rem;
  font-weight: normal;
  width: 100%;
  position: absolute;
  bottom: 5px;
}

label.consoleLabel {
  color: #9e9d9d; /* #607c96; */ /* OVERRIDE WORKS */
  font-family: 'Nunito Sans', sans-serif;
  top: -2px !important;
}

.MuiTextField-root fieldset {
  border-color: #DFDFDF !important; /* OVERRIDE WORKS */
}

.MuiOutlinedInput-root {
  background-color: #ffffff !important;
}

.consoleInput {
  font-weight: 400 !important;
  color: #607C96 !important; /* OVERRIDE WORKS */
  font-size: 1.375rem !important;
  font-family: 'Nunito Sans', sans-serif !important;
  padding: 10px 14px !important;
}

.validoLoginInput {
  color: #ffffff !important;
  padding: 12px 14px !important;
  font-family: 'Nunito Sans', sans-serif !important;
}

.docketInput {
  padding: 12px 10px !important;
}

.profile-button {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 400 !important;
  text-transform: none;
  font-size: 13px !important;
  border-radius: 5px !important;
  border-width: 1px !important;
  letter-spacing: 0;
  color: #383838 !important;
  height: 60px;
  border-color: #dfdfdf !important;
  background-color: #EDEDED !important;
  text-align: left !important;
  line-height: 1.4rem !important;
  position: relative;
  cursor: pointer;
}

.content-center {
  margin: 0;
  margin-left: 8px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%)
}

.profile-button.racoon-avatar {
  background-image: url('assets/svg/profile-racoon.svg');
}

.profile-button.koala-avatar {
  background-image: url('assets/svg/profile-koala.svg');
}

.profile-button.cat-white-avatar {
  background-image: url('assets/svg/profile-cat-white.svg');
}

.profile-button.bear-avatar {
  background-image: url('assets/svg/profile-bear.svg');
}

.profile-button:active {
  border: 2px solid #ff00fc !important;
}

.avatar-button {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 400 !important;
  text-transform: none;
  font-size: 1rem !important;
  border-radius: 5px !important;
  border-width: 1px !important;
  letter-spacing: 0;
  color: #383838 !important;
  height: 100px;
  width: 95%;
  border: 1px solid;
  border-color: #dfdfdf !important;
  background-color: #ffffff !important;
  position: relative;
  cursor: pointer;
}

.avatar-button:hover {
}

.avatar-button:active {
  border: 1px solid #ff00fc !important;
}

.no-avatar {
  background-image: url('assets/svg/avatar-placeholder.svg');
}

.fox-avatar {
  background-image: url('assets/svg/avatar-fox.svg');
}

.warthog-avatar {
  background-image: url('assets/svg/avatar-warthog.svg');
}

.bear-avatar {
  background-image: url('assets/svg/avatar-bear.svg');
}

.racoon-avatar {
  background-image: url('assets/svg/avatar-racoon.svg');
}

.beaver-avatar {
  background-image: url('assets/svg/avatar-beaver.svg');
}

.zebra-avatar {
  background-image: url('assets/svg/avatar-zebra.svg');
}

.ostrich-avatar {
  background-image: url('assets/svg/avatar-ostrich.svg');
}

.skunk-avatar {
  background-image: url('assets/svg/avatar-skunk.svg');
}

.chipmunk-avatar {
  background-image: url('assets/svg/avatar-chipmunk.svg');
}

.deer-avatar {
  background-image: url('assets/svg/avatar-deer.svg');
}

.platypus-avatar {
  background-image: url('assets/svg/avatar-platypus.svg');
}

.koala-avatar {
  background-image: url('assets/svg/avatar-koala.svg');
}

.cat-orange-avatar {
  background-image: url('assets/svg/avatar-cat-orange.svg');
}

.dog-black-avatar {
  background-image: url('assets/svg/avatar-dog-black.svg');
}

.cat-black-avatar {
  background-image: url('assets/svg/avatar-cat-black.svg');
}

.cat-white-avatar {
  background-image: url('assets/svg/avatar-cat-white.svg');
}

.dog-white-avatar {
  background-image: url('assets/svg/avatar-dog-white.svg');
}

.walrus-avatar {
  background-image: url('assets/svg/avatar-walrus.svg');
}

.fennec-fox-avatar {
  background-image: url('assets/svg/avatar-fennec-fox.svg');
}

.elephant-avatar {
  background-image: url('assets/svg/avatar-elephant.svg');
}

.polar-bear-avatar {
  background-image: url('assets/svg/avatar-polar-bear.svg');
}

.penguin-avatar {
  background-image: url('assets/svg/avatar-penguin.svg');
}


/* Header
---------------------------------------------------------------------------------------------- */

#header {
  background-color: #147AC6;
  color: #ffffff;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  height: 60px;
}

.home-icon {
  cursor: pointer;
}

.home-icon img {
  min-width: 23px;
  height: auto;

}

.users-icon {
  cursor: pointer;
}


.users-icon img {
  width: 40px;
  height: auto;
  margin-left: 30px;
  margin-top: 2px;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-title {
  text-align: right;
  margin-right: 6rem;
}

.page-title p {
  margin: 0px;
  font-size: 0.875rem;
  padding-bottom: 0rem;
  opacity: 0.6;
  font-weight: 600;
}

.page-title h3 {
  margin: 0px;
  font-weight: 300;
  font-size: 1.375rem;
  font-weight: 400;
}

a#valido-logo {
  display: block;
  width: 110px;
  height: 52px;
  background-image: url('assets/svg/valido-logo.svg');
  background-repeat: no-repeat;
  background-size: 110px 52px;
  padding-right: 12px;
}


a#valido-logo span {
  visibility: hidden;
}

div#valido-logo {
  display: block;
  width: 110px;
  height: 52px;
  background-image: url('assets/svg/valido-logo.svg');
  background-repeat: no-repeat;
  background-size: 110px 52px;
  padding-right: 12px;
  cursor: pointer;
}


div#valido-logo span {
  visibility: hidden;
}



/* Content
---------------------------------------------------------------------------------------------- */

#wrapper {
  max-width: 100%; /* 1143px; */
  margin: 0 auto;
}

#main {
  background: #FAFBFB;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 7px;
  margin-left: 7px;
  margin-right: 7px;
  display: grid;
  grid-template-columns: 84% 16%;
}

.stations-screen .col-10 {
  padding: 20px 150px 50px 60px;
  max-width: 90%;
}

.load-credit-screen .col-10,
.col-10 {
  padding: 20px 60px 50px 10px;
  max-width: 100%;
}

.col-2 {
  background: url('assets/images/side-ph.jpg');
  background-size: cover;
  background-position: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.has-icon img {
  vertical-align: middle;
  padding-right: 0.5rem;
}

.padding-small {
  padding-bottom: 0.65rem;
}

.padding-top-medium {
  display: block;
  padding-top: 1.25rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.grid.docket-buttons,
.grid.credit-buttons {
  grid-gap: 2px;
}

.grid.staff-buttons.scroll-box {
  max-height: 352px;
  overflow: auto;
  position: relative;
  min-height: 150px;
}

.grid.docket-buttons button,
.grid.credit-buttons button {
  height: 82px;
}

.grid.pin-buttons button {
  height: 100px;
}

.grid.station-buttons button {
  height: 100px;
}

.grid .grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 60px; /* was 100px */
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.grid-item:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: calc(5 / 8 * 100%);
}

.grid-item:active,
.grid-item:focus-within,
.grid-item.selected {
  border: 3px solid #fe00ff;
}

.grid-item span {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 1.25em;
}

.with-icon img {
  /* filter: invert(100%) sepia(5%) saturate(167%) hue-rotate(293deg) brightness(115%) contrast(900%); */
  padding-right: 0.5em;
}

.button-row {
  margin-top: 8rem;
  display: flex;
}

.stations-screen .button-row div:last-child {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.button {
  padding: 18px 40px;
  border: 1px solid #147ac6;
  border-radius: 4px;
  font-size: 12px;
  background: #147ac6;
  color: #fff;
  margin-right: 0.5rem;
}

.button:last-child {
  margin-right: 0px;
}

.button.outline {
  background: transparent;
  color: #147ac6;
}


/* Login page styles */

.loginBG {
  background: url('assets/images/login-screen-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #147ac6;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  max-width: 100vw !important;
}

.loginBG .valido-login-logo {
  width: 275px;
  height: 47px;
  margin-left: 95px;
  margin-bottom: 28px;
}

.loginBG .valido-login-byline {
  width: 540px;
  height: 24px;
  margin-left: -45px;
  margin-bottom: 20px;
}

.modal-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  padding: 0 90px 0 110px;
}

.modal-inner-wrapper .MuiOutlinedInput-root {
  background: transparent !important;
}

.modal-inner-wrapper .MuiTextField-root fieldset {
  border-color: #ffffff !important; /* OVERRIDE WORKS */
}

.remember-me {
  color: #ffffff;
  font-size: 0.875rem;
  display: block;
  float: left;
  margin-top: 10px;
}

.password-link {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
  display: block;
  float: right;
  margin-top: 10px;
}

.login-checkbox-label .MuiTypography-root {
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 0.875rem !important;
}

.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: rgba(19,75,115,0.4) !important;
}


/* Modal overlay styles */

.modalOverlayBG {
  background-color: rgba(0,0,0,0.5);
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  max-width: 100vw !important;
  z-index: 4;
  position: fixed;
}


/* Profile icons - add credit page */

.grid-item.white {
  background: #ffffff;
  color: #383838;
  border: 1px solid #DFDFDF;
}

.grid-item.profile span {
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 10px 20px;
  box-sizing: border-box;
  line-height: 22px;
}

.grid-item.profile span img {
  width: 37px;
  height: 37px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
  /* padding-right: 0.8rem; */
  border-radius: 50%;
  border: 2px solid white;
  margin: 0 20px 0 0;
  padding: 0;
}

.purple.selected,
.grid-item.purple:active,
.grid-item.purple:focus-within {
  background: #5a4b78;
}

.grid-item.grey {
  background: #EDEDED;
  color: #000;
  border: 1px solid #DFDFDF;
  max-height: 60px;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px -2px 6px 0px inset; */
}

.grid-item:hover,
.grid-item:active,
.grid-item:focus-within,
.grid-item.selected {
  border: 3px solid #fe00ff;
}

/* Profile icons - staff page */

.grid-item.staff-profile span {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 10px 20px;
  box-sizing: border-box;
  line-height: 22px;
}


.grid-item.staff-profile:hover,
.grid-item.staff-profile:active,
.grid-item.staff-profile.selected {
  border: 3px solid #FC00FC ;
}

.grid-item.staff-profile span img {
  width: 60px;
  height: 60px;
  /* padding-right: 0.8rem; */
  border-radius: 50%;
  border: 2px solid white;
  margin: 0 0 5px 0;
  padding: 0;
}

.grid-item.staff-profile:before {
  padding-top: 110px;
}

.overlay-fade {
  display: block;
  width: 780px;
  height: 120px;
//background: url('assets/images/profiles-overlay-fade.png');
  background-size: cover;
  background-position: center;
  z-index: 999999;
  margin-top: -120px;
  position: absolute;
  left: 50%;
  margin-left: -516px;
}


/* Load credit styles */

.staff-button-wrapper {
  height: 58px;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
}

.credit-button-wrapper {
  background: url('assets/svg/button-credit-bg.svg');
  background-size: 97%;
  background-position: center;
  background-repeat: no-repeat;
  height: 82px;
  border-radius: 12px;
}

.docket-button-wrapper {
  background: url('assets/svg/button-docket-bg.svg');
  background-size: 97%;
  background-position: center;
  background-repeat: no-repeat;
  height: 82px;
  /* border: 1px solid #DFDFDF; */
  border-radius: 8px;
}

.modalOverlayBG .docket-button-wrapper {
  background: none;
}

.credit-button-wrapper button {
  height: 82px;
  width: 100%;
}

.docket-button-wrapper button {
  height: 82px;
  width: 100%;
}

.staff-button-wrapper button {
  height: 60px;
  width: 100%;
}

.staff-button-wrapper div-img {
  height: 60px;
  width: 100%;
}
/* Reset pin screen styles */

.reset-pin-screen {
  display: flex;
  flex-direction: row;
}

.reset-pin-screen .left-column {
  width: 35%;
  margin-right: 10%;
}

.reset-pin-screen .right-column {
  width: 45%;
  margin-left: 10%;
}

.choose-your-avatar {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}

ul.avatar-choices {
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

ul.avatar-choices li {
  margin-bottom: 16px;
}

ul.avatar-choices li a {
  display: block;
  width: 56px;
  height: 56px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  border: 3px solid #ffffff;
}

ul.avatar-choices li a:hover,
ul.avatar-choices li a:active,
ul.avatar-choices li a.selected {
  border: 3px solid #ff00fc;
}


/* Calculator mockup */

.button-row {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button-row.no-margin-top {
  margin-top: 2em;
}

.button {
  display: block;
  padding: 18px 40px 18px 20px;
  border: 1px solid #147ac6;
  border-radius: 4px;
  background: #147ac6;
  color: #fff;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
  font-weight: normal;
}

.button.outline.red {
  border: 1px solid #e13f38;
  color: #e13f38;
}

.button.outline.orange {
  border: 1px solid #ef8921;
  color: #ef8921;
}

.button.outline.green {
  border: 1px solid #60cc46;
  color: #60cc46;
}

.button:last-child {
  margin-bottom: 0px;
}

.button.outline {
  background: transparent;
  color: #147ac6;
}

.button.outline img {
  max-width: 30px;
  height: auto;
}

.calculator {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.number,
.calculator
.entry {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e6e5e5;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 1) 8%);
  background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 1) 8%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 1) 8%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#efefef",GradientType=1);
}

.number-disable
{
  opacity: 0.5;
  cursor: default;
}

.entry-text{
  background: #ffffff;
  text-align: center;
  text-align: center;
  font-size: 30px;
  display: block;
  letter-spacing: 12pt;
}

.calculator .entry:before
{
  display: block;
  width: 100%;
  padding-top: calc(0 / 19 * 100%);
}

.calculator .number:before {
  display: block;
  width: 100%;
  padding-top: calc(0 / 19 * 100%);
  content: "";
}
.number {
}

.number span {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.625em;
  line-height: 0.75em;
  color: #607C96;
}

.number.text-button span {
  font-size: 1.375rem;
}

.grid.calculator {
  grid-template-columns: 187px 2fr 123px;
}

.calculator .entry {
  grid-column: 1 / 4;
  max-height: 45px;
}

.inner-row {
  display: grid;
  grid-template-columns: 4fr 75px;
  grid-gap: 60px;
}

.inner-row.calc-row {
  grid-template-columns: 1fr 200px;
  grid-gap: 0px;
}

.pin-calc {
  height: 280px;
  margin-top: 60px;
}

.pin-calculator {
  height: 100%;
}

.pin-calculator .calculator {
  height: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid.small-grid {
  grid-template-columns: 1fr;
  grid-gap: 0px;
  height: 100%;
}

.grid .grid-item.outline {
  border: 2px solid #147ac6;
  color: #147ac6;
  min-height: 70px;
  width: 100%;
  justify-self: flex-end; /* check this */
}

.disabled,
.disabled span {
  cursor: default;
  opacity: 0.5;
}

.grid .grid-item.outline:before {
  padding-top: calc(1 / 1 * 100%);
}


/* Icons */

.register-icon {
  width: 28px;
}

.privacy-icon {
  width: 22px;
}


/* Typography
---------------------------------------------------------------------------------------------- */

h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.text-blue {
  color: #147AC6;
}

.text-teal {
  color: #34AEBE;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  font-size: 1.375em;
  font-weight: 400;
}

h1.has-icon {
  padding-left: 50px;
  background-repeat: no-repeat;
  margin: 15px 0 0 0;
  padding-bottom: 0;
}

h3.has-icon {
  padding-left: 30px;
  background-repeat: no-repeat;
  margin-top: 30px;
  margin-bottom: 10px;
}

h3.no-icon {
  margin: 20px 0 5px 0;
}

h1.has-icon.stations {
  background-image: url('assets/svg/h1-icon-stations.svg');
  background-size: 35px;
  background-position: left 0px;
}

h3.has-icon.set-station {
  background-image: url('assets/svg/h3-icon-set-station.svg');
  background-size: 22px;
  background-position: left 3px;
}

h3.has-icon.select-station {
  background-image: url('assets/svg/h3-icon-select-station.svg');
  background-size: 26px;
  background-position: left 0px;
}

h3.has-icon.transaction-id {
  background-image: url('assets/svg/h3-icon-transaction-id.svg');
  background-size: 24px;
  background-position: left 5px;
}

h3.has-icon.parking-credit {
  background-image: url('assets/svg/h3-icon-parking-credit.svg');
  background-size: 15px;
  background-position: left 0px;
}

h3.has-icon.cashier-code {
  background-image: url('assets/svg/h3-icon-cashier-id.svg');
  background-size: 25px;
  background-position: left 0px;
}

h3.has-icon.signed-on {
  background-image: url('assets/svg/h3-icon-signed-on.svg');
  background-size: 20px;
  background-position: left 4px;
}

h3.has-icon.signed-off {
  background-image: url('assets/svg/h3-icon-signed-off.svg');
  background-size: 20px;
  background-position: left 4px;
}



/* Buttons
---------------------------------------------------------------------------------------------- */

/* button {
	width: 100%;
	float: left;
	background-color: white;
	color: #9e9d9d;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	border-radius: 6px;
	border: 2px solid;
	border-color: #bfcad5;
} */



/* Footer
---------------------------------------------------------------------------------------------- */

/* #footer-wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 0;
	height: auto;
	position: fixed;
	bottom: 0;
	z-index: 9999;
	background-color: #2a558c;
}

#footer {
	margin: 0 auto;
	padding: 0 16px;
	height: auto;
	min-height: 48px;
	color: white;
} */


/* Custom Flow Styles
---------------------------------------------------------------------------------------------- */





/* Media Queries
---------------------------------------------------------------------------------------------- */

@media screen and (max-width: 400px) {



}

@media screen and (max-width: 999px) {

  .col-2 {
    display: none;
  }

  #main {
    display: block;
  }

  .stations-screen .col-10,
  .col-10 {
    padding: 20px 50px;
    max-width: calc(100% - 100px);
    width: calc(100% - 100px);
  }

  .load-credit-screen .col-10 {
    padding: 20px 20px 20px 20px;
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
  }

}

@media screen and (min-width: 1000px) and (max-width: 1199px) {

  .stations-screen .col-10,
  .col-10 {
    padding: 20px 60px 20px 60px;
    max-width: calc(100% - 120px);
    padding-top: 0px;
  }

  .load-credit-screen .col-10 {
    padding: 20px 40px 20px 20px;
    max-width: calc(100% - 60px);
  }
}

@media screen and (min-width: 1200px) {

  .stations-screen .col-10 {
    max-width: 900px;
  }

  .load-credit-screen .col-10,
  .col-10 {
    max-width: 900px;
  }

}

/* new css -----*/

#main-home {
  background: #FAFBFB;
  border-radius: 6px;
  margin-left: 0px;
  display: grid;
  grid-template-columns: 100% 0%;
  padding-left: 24px;
  padding-right: 24px;
}

.grid-home {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 16px;
}

.grid-home.pin-buttons {
  height: 180px;
  margin: 16px;
}

.col-header {
  padding: 10px;
  max-width: 100%;
}

.text-pink {
  color: #BF37BF;
  height:16px;
}

#main-no-grid {
  background: #FAFBFB;
  border-radius: 6px;
//border: 1px solid rgb(223, 223, 223, 0.85);
  margin-left: 0px;
  display: block;
  min-height: 600px;
  padding-left: 24px;
  padding-right: 24px;
}

.nav-icons {
  cursor: pointer;
  margin-left: 48px;
}

.nav-text {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 600 !important;
  text-transform: none;
  font-size: 2.25rem !important;
  letter-spacing: 0;
  color: #FFFFFF !important;
  text-indent: 100px;
}

.load-credit-img {
  width: 40px;
  height: 40px;
}

.avatar-img {
  background-repeat: no-repeat;
  background-position: center 12px;
  background-size: 60px;
  width: 50px;
  height: 50px;
}

.content-center-avatar {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-bottom-center-avatar {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.modalOverlayBGHide {
  display: none;
}

/* Home Button styles*/

.home-button {
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: normal !important;
  text-transform: none;
  font-size: 30px !important;
  border-radius: 5px !important;
  border-width: 1px !important;
  letter-spacing: 0;
  color: #147AC6 !important;
  height: 180px;
  box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #FFFFFF;
  position: relative;
  cursor: pointer;
}

.home-button:hover,
.home-button:active {
  box-shadow:#ff00fc 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.2);
}

.user-avatar {
  background-repeat: no-repeat;
  background-position: 25px center;
  background-image: url('assets/svg/Users.svg');
  background-repeat: no-repeat;
}

.campaign-avatar {
  background-repeat: no-repeat;
  background-position: 25px center;
  background-image: url('assets/svg/Campaign.svg');
  background-repeat: no-repeat;
  margin-right: 8px;
}

.pos-avatar {
  background-repeat: no-repeat;
  background-position: 25px center;
  background-image: url('assets/svg/POS.svg');
  background-repeat: no-repeat;
}

.activity-avatar {
  background-repeat: no-repeat;
  background-position: 25px center;
  background-image: url('assets/svg/Activity.svg');
  background-repeat: no-repeat;
}

.console-avatar {
  background-repeat: no-repeat;
  background-position: 25px center;
  background-image: url('assets/svg/Console.svg');
  background-repeat: no-repeat;
}

.exit-background {
  background-repeat: no-repeat;
  background-position: 0px center;
  background-image: url('assets/images/exit-background.jpg');
  background-repeat: no-repeat;
}

.home-content-center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}

.home-content-text {
  padding-left: 50px;
}

.home-content-forward-icon {
  float: right;
}

.home-content-exit-text {
  padding-left: 0px;
  color:#FFFFFF;
}

.home-exit-content-center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
}

.home-exit-div {
  padding-left: 5px;
}

.home-exit-icon {
  padding:20px;
  padding-right: 10px;
  padding-left: 10px;
  width: 78px;
  height: 78px;
}

.valido-credit-loaded-center {
  margin: 0;
  margin-left: 8px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%)
}

div#valido-logo {
  display: block;
  width: 110px;
  height: 52px;
  background-image: url('assets/svg/valido-logo.svg');
  background-repeat: no-repeat;
  background-size: 110px 52px;
  padding-right: 12px;
  cursor: pointer;
}

div#valido-logo span {
  visibility: hidden;
}

.text-heading-pink {
  color: #B71FB7;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.4px;
  cursor: pointer;
  margin-top: 16px;
}


.text-heading-blue {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  color:#147AC6;
  cursor: pointer;
  margin-top: 16px;
}

.nvpInput {
  font-weight: 400 !important;
  color: #383838 !important; /* OVERRIDE WORKS */
  font-family: 'Nunito Sans', sans-serif !important;
  /*padding: 13px 14px !important;*/

}

label.nvpLabel {
  color: #383838; /* #607c96; */ /* OVERRIDE WORKS */
  font-family: 'Nunito Sans', sans-serif;
  top: 0px !important;
}

.MuiSelect-select{
  color: #383838 !important; /* OVERRIDE WORKS */
  font-family: 'Nunito Sans', sans-serif;
  /*margin-top: -4px !important;
  margin-bottom: 5px !important;*/
}


.campaign-tile {
  background-color: #F0F4F8;
  height: 72px;
  border-rdius: 4px;
  position: relative;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #2B2B2B;
  font-weight: 600;
}

.campaign-tile-center {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
